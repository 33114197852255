import React, { Component } from 'react';
import { Button, Card, Table, Space, Pagination, message, Tooltip, Modal } from 'antd';
import { SnippetsOutlined } from '@ant-design/icons';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import './ReportManagePerson.less';

/**
 * 个人报告管理
 */
class ReportManagePerson extends Component {

  constructor(props) {
    super(props);
    // 组件状态

    this.state = {
      list: [],
      currentPage: 1,
      count: 1,
      tableLoading: false,
    };
  }

  // 列表项
  columns = [
    {
      title: '测评项目',
      dataIndex: 'course_name',
      align: 'center',
      width: '30%',
    },
    {
      title: '测评时间',
      dataIndex: 'start_time',
      align: 'center',
      width: '25%',
    },
    {
      title: '得分',
      dataIndex: 'course_score',
      align: 'center',
      width: '20%',
    },
    {
      title: '操作',
      dataIndex: 'operator',
      align: 'center',
      width: '25%',
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="查看报告">
            <Button shape="circle" onClick={() => { this.viewReport(record) }}><SnippetsOutlined /></Button>
          </Tooltip>
        </Space>
      )
    },
  ];

  // 组件挂载
  componentDidMount() {
    this.seacrhDataFun({ page: 1 });
  }

  // 获取列表数据
  seacrhDataFun = (data = null) => {

    let tempParam = {};
    tempParam.page = this.state.currentPage;

    if (data && data.page) {
      tempParam.page = data.page;
    }

    this.setState({ tableLoading: true });
    axios.get(baseConfig.httpUrl + `/api/training/student-training-record`, { params: tempParam }).then(
      response => {
        if (response.data.res === 1) {
          const { list, page, count } = response.data;

          list.map((dataObj) => {
            return dataObj.key = dataObj.id
          });

          this.setState({
            list: [...list],
            currentPage: page * 1,
            count: count,
            tableLoading: false
          });
        }
        else {
          this.setState({
            list: [],
            currentPage: 1,
            count: 1,
            tableLoading: false
          });
          message.error({
            content: response.data.msg,
            className: 'custom-class',
          });
        }
      },
      error => { }
    );
  }

  // 换页
  changePage = (pageNum, pageSize) => {
    this.seacrhDataFun({ page: pageNum });
  }

  // 查看报告
  viewReport = (data) => {
    if (data.report_url) {
      window.open(data.report_url, '_blank');
    }
  }


  render() {
    return (
      <Modal
        title="个人报告"
        visible={this.props.visible}
        width={1000}
        onCancel={() => { this.props.closeFun() }}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        className='modals reportManagePerson'
      >
        <Card title="" style={{ minHeight: '100%' }}>
          {/* 列表 */}
          <Table
            dataSource={this.state.list}
            pagination={false}
            columns={this.columns}
            loading={this.state.tableLoading}
            style={{ height: 500, overflowY: 'auto' }}
          >
          </Table>

          {/* 分页 */}
          <Pagination
            onChange={this.changePage}
            style={{ marginTop: 30 }}
            defaultCurrent={1}
            current={this.state.currentPage}
            total={this.state.count}
            hideOnSinglePage={true}
            pageSize={20}
            showQuickJumper={{
              goButton: <span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <Button type='primary'>Go</Button></span>,
            }}
            showTotal={(total, range) => `当前显示${range[0]}-${range[1]}条  共${total}条记录 `}
            showSizeChanger={false}
          />
        </Card >
      </Modal>
    )
  }
}

export default ReportManagePerson
