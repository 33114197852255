import React, { Component } from 'react';
import { Menu, Avatar } from 'antd';
import { connect } from 'react-redux';
import { replaceBreadcrumber } from '../../redux/actions/Breadcrumber';
import MyNavLink from '../MyNavLink/MyNavLink';
import baseConfig from '../../config/BaseConfig';
import './LeftNav.less';
import { changeRoleLeftNav } from '../../redux/actions/LeftNav';
import MenuDataFun from '../../config/MenuConfig';
import portraitImg from '../../assets/portrait.png'

const { SubMenu } = Menu;

/* 左侧导航 组件 */
class LeftNav extends Component {

    state = {
        menuList: [],
        username: '',
        gradeName: '',
    };

    componentDidMount() {
        // 获取菜单数据
        this.setState({ menuList: [...MenuDataFun(this.props.loginData)], username: this.props.loginData.uname, gradeName: this.props.loginData.full_name });
    }

    // 创建菜单item
    createMenuItems = (menuList) => {

        return menuList.map((menuObj) => {
            if (menuObj.children && menuObj.children.length !== 0) {
                return (
                    <SubMenu key={baseConfig.routeUrl + menuObj.key} title={menuObj.title}>
                        {
                            this.createMenuItems(menuObj.children)
                        }
                    </SubMenu>
                )
            }
            else {
                return (<Menu.Item key={baseConfig.routeUrl + menuObj.key} onClick={this.clickMenuItem(menuObj.key, menuObj.title, menuObj.father, menuObj.father_name)}> <MyNavLink to={baseConfig.routeUrl + menuObj.key}>{menuObj.title}</MyNavLink> </Menu.Item>)
            }
        });
    }

    // 点击标签
    clickMenuItem = (itemPath, itemPathName, itemFather, fatherName) => {
        return () => {
            // this.setState({ openKeys: [baseConfig.routeUrl + itemFather], selectedKeys: [baseConfig.routeUrl + itemPath] });
            this.props.replace([{ path: itemFather, name: fatherName, grade: 1 }, { path: itemPath, name: itemPathName, grade: 2 }]);
        }
    }

    render() {

        let defaultPath = MenuDataFun(this.props.loginData)
        let tempDefaultSelectedKeys = defaultPath[0].children ? defaultPath[0].children[0].key : defaultPath[0].key

        return (
            <div className='leftNav'>
                <div className='user-info'>
                    <Avatar className='portrait' size={100} src={portraitImg} />
                    <p className='userName'>{this.state.username}</p>
                    <p className='gradeName'>{this.state.gradeName}</p>
                </div>
                <div className='leftNav-menu'>
                    <Menu
                        mode="inline"
                        defaultOpenKeys={[baseConfig.routeUrl + defaultPath[0].key]}
                        defaultSelectedKeys={[baseConfig.routeUrl + tempDefaultSelectedKeys]}
                        // openKeys={['']}
                        // selectedKeys={['']}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                        {
                            this.createMenuItems(this.state.menuList)
                        }
                    </Menu>
                </div>
                <div className='leftNav_sysinfo'>版本：V1.0.0.23031</div>
            </div>
        )
    }
}


// 容器组件
const mapStateToProps = (state) => {
    return { loginData: state.login }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeRole: (data) => { dispatch(changeRoleLeftNav(data)) },
        replace: (data) => { dispatch(replaceBreadcrumber(data)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftNav);